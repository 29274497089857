import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { format } from 'date-fns';
import BoxLayout, { LinkBox } from '@esrh/core/src/BoxLayout';
import LoadButton from '@esrh/app/src/components/Routes/Hvo/Frontpage/LoadButton';
import { REPORTS } from '@esrh/app/src/util';
import useLocale from '@visma/react-app-locale-utils/lib/useLocale';
import { fi, sv } from 'date-fns/locale';

export default function HankeInfo({ hanke }) {
  const [loading, setLoading] = useState([]);
  const intl = useIntl();
  const [locale] = useLocale();

  const reportBasics = {
    report: REPORTS.HankkeenOsallistujat,
    format: 'pdf',
    hankeId: hanke.hankeId,
    loading,
    setLoading,
    locale,
  };

  const hankeInfo = [
    {
      hankeName: hanke.hankeNimi,
      hankeId: hanke.hankeId,
      hanketoteuttaja: `${hanke.hankeToteuttaja} ${hanke.hankeToteuttajaNimi}`,
      hankeFinancier: hanke.rahoittajaviranomainen,
    },
    {
      toimintalinja: hanke.toimintalinja,
      hankePriority: hanke.investointiprioriteetti,
      erityistavoite: hanke.erityistavoite,
      startDate: format(new Date(hanke.aloitusPvm), 'P', {
        locale: locale === 'sv-SE' ? sv : fi,
      }),
      hankeState: hanke.makuloitu
        ? intl.formatMessage({ defaultMessage: 'Päättynyt' }).toUpperCase()
        : intl.formatMessage({ defaultMessage: 'Käynnissä' }).toUpperCase(),
    },
  ];

  return (
    <BoxLayout dataArray={hankeInfo} labelWidth={200}>
      <LinkBox>
        <div>
          <LoadButton {...reportBasics} />{' '}
          <LoadButton {...reportBasics} rajaus={0} suffix={'C'} />{' '}
          <LoadButton {...reportBasics} rajaus={1} suffix={'I'} />
        </div>
        <div>
          <LoadButton {...reportBasics} report={REPORTS.HankeIndikaattorit} />
        </div>
      </LinkBox>
    </BoxLayout>
  );
}
